
import { Options, Vue } from 'vue-class-component';

import TryItAddress from './components/try-it/TryItAddress.vue';
import TryItPhone from './components/try-it/TryItPhone.vue';
import TryItEmail from './components/try-it/TryItEmail.vue';
import TryItCIMA from './components/try-it/TryItCIMA.vue';

@Options({
  components: {
    TryItAddress,
    TryItPhone,
    TryItEmail,
    TryItCIMA,
  },
})
export default class PageApiTryIt extends Vue {
  public tabs = [
    {
      component: 'TryItCIMA',
      title: 'CIMA (Complete Integrate Marketing Append)',
    },
    {
      component: 'TryItAddress',
      title: 'I have address',
    },
    {
      component: 'TryItPhone',
      title: 'Reverse phone append',
    },
    {
      component: 'TryItEmail',
      title: 'Reverse email append',
    },
  ]

  public currentTab = this.tabs[0].component;
}
