
import { Options, Vue } from 'vue-class-component';
import CodeMirror from '@/common/CodeMirror.vue';
import requests from '@/requests';

@Options({
  components: {
    CodeMirror,
  },
})
export default class ApiTryItEmail extends Vue {
  public code: Record<string, unknown> | string = {
    client_id: '3',
    FName: 'Marry',
    LName: 'Smith',
    Address1: '3333 Renaissance Blvd',
    Address2: '',
    City: 'Bonita Springs',
    State: 'FL',
    Zip5: '34134',
    Phone: 1234567890,
    Phone2: 1234567891,
    Email: 'msmith@sample.com',
    IP: '192.158.1.38',
    immediate: false,
  }

  public apiResponse = '{\n  message: "", \n}';

  public loader = false;

  public handleChangeValue(value: string): void {
    this.code = JSON.parse(value);
  }

  public sendRequest(): void {
    const code = JSON.parse(JSON.stringify(this.code));

    if (typeof code === 'object') {
      this.loader = true;

      requests.api.sendSampleCode(code, '/cima')
        .then((res) => {
          this.apiResponse = res.data;
        })
        .finally(() => {
          this.loader = false;
        });
    }
  }
}
