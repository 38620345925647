
import { Options, Vue } from 'vue-class-component';
import CodeMirror from '@/common/CodeMirror.vue';
import requests from '@/requests';

@Options({
  components: {
    CodeMirror,
  },
})
export default class ApiTryItEmail extends Vue {
  public code: Record<string, unknown> | string = {
    client_id: '12345',
    match_level: 'household',
    email: 'tomclancy@aol.com',
    immediate: true,
  }

  public apiResponse = '{\n  message: "", \n}';

  public loader = false;

  public handleChangeValue(value: string): void {
    this.code = JSON.parse(value);
  }

  public sendRequest(): void {
    const code = JSON.parse(JSON.stringify(this.code));

    if (typeof code === 'object') {
      this.loader = true;

      requests.api.sendSampleCode(code, '/email')
        .then((res) => {
          this.apiResponse = res.data;
        })
        .finally(() => {
          this.loader = false;
        });
    }
  }
}
