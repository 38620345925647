
import { Options, Vue } from 'vue-class-component';
import CodeMirror from '@/common/CodeMirror.vue';
import requests from '@/requests';

@Options({
  components: {
    CodeMirror,
  },
})
export default class ApiTryItAddress extends Vue {
  public code: Record<string, unknown> | string = {
    address: {
      address1: '654 105th Ave',
      address2: '',
      city: 'Naples',
      state: 'FL',
      zip: '34108',
    },
    client_id: '1552',
    name: {
      first_name: 'Angela',
      last_name: 'Smith',
      middle_name: '',
      prefix_name: '',
      suffix_name: '',
    },
    paf_id: 'ABD123456789',
    process: ['pcoa', 'ncoa', 'fp', 'fe'],
    immediate: true,
  };

  public apiResponse = '{\n  message: "", \n}';

  public loader = false;

  public handleChangeValue(value: string): void {
    this.code = JSON.parse(value);
  }

  public sendRequest(): void {
    const code = JSON.parse(JSON.stringify(this.code));

    if (typeof code === 'object') {
      this.loader = true;

      requests.api.sendSampleCode(code, '/address')
        .then((res) => {
          this.apiResponse = res.data;
        })
        .finally(() => {
          this.loader = false;
        });
    }
  }
}
